import React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        padding: "50px 20px",
      }}
    >
      <Stack direction="column" alignItems="center">
        <img
          src="./images/bully-brush.png"
          alt="Bully"
          style={{
            width: "160px",
          }}
        />
        <Typography
          sx={{
            fontFamily: "NicoMoji",
            fontSize: "32px",
            background: "linear-gradient(180deg, #C5D4E9 0%, #B9C7E1 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          FIND US ON
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="20px"
          sx={{
            marginTop: "20px",
            ".MuiLink-root": {
              cursor: "pointer",
            },
            img: {
              width: "60px",
            },
          }}
        >
          <Link href="https://x.com/TapTheBull" target="_blank">
            <img src="./images/x-logo.png" alt="X" />
          </Link>
          <Link href="https://t.me/tap7he8u11" target="_blank">
            <img src="./images/telegram-logo.png" alt="Telegram" />
          </Link>
          <Link>
            <img src="./images/dexscreener-logo.png" alt="Dexscreener" />
          </Link>
          <Link>
            <img src="./images/dextools-logo.png" alt="Dextools" />
          </Link>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="20px"
          sx={{
            marginTop: "20px",
            padding: "10px 30px",
            borderRadius: "40px",
            background: "linear-gradient(180deg, #C5D4E9 0%, #B9C7E1 100%)",
            img: {
              height: "60px",
            },
          }}
        >
          <Link target="_blank" href="https://pinksale.finance">
            <img src="./images/pinksale-logo.png" alt="Pinksale" />
          </Link>
          <Link target="_blank" href="https://dexview.com">
            <img src="./images/dexview-logo.png" alt="DEXView" />
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
