import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const Tokenomics = () => {
  return (
    <Box
      id="tokenomics"
      sx={{
        width: "100%",
        padding: { xs: "50px 10px", sm: "50px 20px" },
      }}
    >
      <Stack direction="column" alignItems="center">
        <Typography
          sx={{
            fontFamily: "NicoMoji",
            fontSize: { xs: "42px", sm: "60px" },
            color: "#D7D7D7",
          }}
        >
          Tokenomics
        </Typography>
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: { xs: "100%", sm: "50%" }, padding: "20px" }}
        >
          <img
            src="./images/tokenomics-chart.png"
            alt="Chart"
            style={{
              width: "80%",
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Stack
            sx={{
              padding: "20px",
              ".MuiTypography-root": {
                fontSize: { xs: "16px", sm: "24px" },
                fontWeight: "600",
                color: "#D7D7D7",
              },
              ".MuiStack-root": {
                marginTop: "10px",
                gap: "10px",
              },
              ".color-dot": {
                width: "32px",
                height: "32px",
                borderRadius: "16px",
              },
            }}
          >
            <Typography>TOTAL SUPPLY : 100B</Typography>
            <Stack direction="row" alignItems="center">
              <Box className="color-dot" sx={{ background: "#E08040" }} />
              <Typography>Game Rewards : 50%</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box className="color-dot" sx={{ background: "#E04080" }} />
              <Typography>Team & Development : 10%</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box className="color-dot" sx={{ background: "#80E040" }} />
              <Typography>Community Incentives & Marketing : 10%</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box className="color-dot" sx={{ background: "#8040E0" }} />
              <Typography>Presale : 15%</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box className="color-dot" sx={{ background: "#40E080" }} />
              <Typography>Liquidity Pool : 10%</Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box className="color-dot" sx={{ background: "#4080E0" }} />
              <Typography>Staking Rewards : 5%</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Tokenomics;
