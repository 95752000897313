import React from "react";
import { Container, Link, Stack } from "@mui/material";

const Header = () => {
  return (
    <Container
      sx={{
        position: "fixed",
        height: "100px",
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(10px)",
        zIndex: 10,
      }}
    >
      <Stack
        direction="row"
        alignContent="center"
        justifyContent="space-between"
        sx={{
          width: "100%",
          height: "100%",
          padding: { xs: "10px 10px", sm: "10px 20px" },
        }}
      >
        <img
          src="./logo192.png"
          alt="logo"
          style={{
            height: "100%",
          }}
        />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "end", sm: "center" }}
          justifyContent="center"
          spacing={{ xs: 1, sm: 3 }}
          sx={{
            ".MuiLink-root": {
              fontSize: "16px",
              textDecoration: "none",
              fontWeight: "600",
              cursor: "pointer",
              transition: "color 0.3s",
              color: "#D7D7D7",
              "&:hover": {
                color: "#fff",
              },
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <Link href="#home">HOME</Link>
            <Link href="#about">ABOUT</Link>
            <Link href="#tokenomics">TOKENOMICS</Link>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Link href="#roadmap">ROADMAP</Link>
            <Link
              target="_blank"
              href="https://www.pinksale.finance/solana/launchpad/CzSmXxJkTxzEVC15gxk6ds3JmpvJjHwu1Rrmnszne2Q4"
            >
              PRESALE
            </Link>
            <Link target="_blank" href="./whitepaper.pdf">
              WHITEPAPER
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Header;
